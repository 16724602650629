.el-form {
  width: 500px;
  margin: auto;
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
}
.zjzw > div {
  overflow-y: auto;
  overflow-x: hidden;
  width: 33.33%;
  margin-right: 4%;
}
.zjzw > div > div {
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.fl {
  float: left;
}
.colorPoint {
  float: left;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-top: 14px;
  margin-right: 10px;
}
